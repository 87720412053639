<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <form @submit.prevent="updateStatus" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change status</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <select
              v-model="status"
              ref="sessionStatus"
              class="form-select"
              id="session-status"
            >
              <option disabled value="">Select a status</option>
              <option
                v-for="(status, statusIndex) in statusList"
                :key="`key-${statusIndex}`"
                :value="status.value"
              >{{ status.name }}</option>
            </select>
            <div class="form-text">{{ info }}</div>
          </div>

          <ErrorMessage v-if="error" :error="error"></ErrorMessage>
        </div>
        <div class="modal-footer">
          <SubmitButton
            :text="'Update'"
            :textBusy="'Updating...'"
            :isLoading="isLoading"
            :disabled="isLoading || status === ''"
            class="btn btn-primary"
          ></SubmitButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { httpPost } from '@/core/http';
import { sessionStatusList } from '@/core/setting';

export default {
  name: 'SessionStatusModal',
  components: {
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    sessions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    info() {
      const { sessions } = this;
      return `The new status will be applied to ${sessions.length} session${sessions.length > 1 ? 's' : ''}.`;
    },
  },
  methods: {
    async updateStatus() {
      this.isLoading = true;
      this.error = null;
      try {
        const items = [];
        const { sessions, status } = this;
        const promises = Object.values(sessions).map((session) => {
          const { sessionId } = session;
          items.push({ sessionId, status });
          return httpPost(`/session/${sessionId}`, { status });
        });
        await Promise.all(promises);
        this.$emit('confirm', items);
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.$emit('close');
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      status: '',
      statusList: sessionStatusList,
    };
  },
};
</script>
